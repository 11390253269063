<template>
  <div>
    <ErrorPage v-if="showError" />
    <div class="flex flex-col gap-4 w-full" v-else>
      <c-card class="w-full flex flex-col p-5 pt-11 gap-0">
        <div class="grid grid-cols-3 gap-3">
          <div
            class="flex justify-start items-center gap-3 p-3 border border-cultured rounded-md"
          >
            <c-icon icon-name="icon-clockin" size="s" style="fill: #f15a29" />
            <div class="flex flex-col justify-center items-start gap-0">
              <h3 class="text-xl font-bold text-jet">
                {{ analytics.clockInDays || 0 }}
              </h3>
              <p class="text-xs font-black text-romanSilver uppercase">
                Clocked In (Days)
              </p>
            </div>
          </div>
          <div
            class="flex justify-start items-center gap-3 p-3 border border-cultured rounded-md"
          >
            <c-icon icon-name="icon-clockin" size="s" style="fill: #2176ff" />
            <div class="flex flex-col justify-center items-start gap-0">
              <h3 class="text-xl font-bold text-jet">
                {{ analytics.clockInHours || 0 }}
              </h3>
              <p class="text-xs font-black text-romanSilver uppercase">
                Clocked In (Hours)
              </p>
            </div>
          </div>
          <div
            class="flex justify-start items-center gap-3 p-3 border border-cultured rounded-md"
          >
            <c-icon icon-name="clock-minus" class="text-blueCrayola" size="s" />
            <div class="flex flex-col justify-center items-start gap-0">
              <h3 class="text-xl font-bold text-jet">
                {{ analytics.absence || 0 }}
              </h3>
              <p class="text-xs font-black text-romanSilver uppercase">
                Absence (Days/Hrs)
              </p>
            </div>
          </div>
        </div>
        <CardFooter
          @actionModal="isOpenFilter = true"
          @sortType="$queryBuilder({ sort: $event }, getOrgAbsence)"
          @searchResult="$queryBuilder({ search: $event }, getOrgAbsence)"
        >
          <template v-slot:rightItems>
            <div class="flex justify-start items-center gap-4 h-1/2">
              <template v-if="activeView === 'register'">
                <div
                  @click="onSwitchActiveView('calendar')"
                  class="flex justify-start items-center gap-2"
                >
                  <c-icon
                    icon-name="calendar-broken"
                    size="s"
                    style="fill: transparent; stroke: #f15a29"
                  />
                  <p
                    class="text-base font-semibold text-flame hover:underline cursor-pointer"
                  >
                    Calendar View
                  </p>
                </div>
              </template>
              <template v-else-if="activeView === 'calendar'">
                <div class="flex justify-between items-center p-4 gap-4">
                  <div
                    @click="onSwitchActiveView('register')"
                    class="flex justify-start items-center gap-2"
                  >
                    <c-icon
                      icon-name="calendar-broken"
                      size="s"
                      style="fill: transparent; stroke: #f15a29"
                    />
                    <p
                      class="text-base font-semibold text-flame hover:underline cursor-pointer"
                    >
                      Register View
                    </p>
                  </div>
                  <p class="font-bold text-darkPurple text-lg">
                    {{ $DATEFORMAT(new Date(selectedDate), "MMMM yyyy") }}
                  </p>
                  <div class="flex justify-start items-center gap-6">
                    <span
                      class="cursor-pointer hover:bg-cultured p-2 rounded"
                      @click="$refs.$c.previous()"
                    >
                      <c-icon icon-name="icon-arrow-left" size="xs" />
                    </span>
                    <span
                      class="cursor-pointer hover:bg-cultured p-2 rounded"
                      @click="$refs.$c.next()"
                    >
                      <c-icon icon-name="icon-arrow-right" size="xs" />
                    </span>
                  </div>
                </div>
              </template>
              <div class="ml-3 flex justify-start items-center gap-0">
                <c-icon
                  icon-name="sort_size"
                  class="cursor-pointer"
                  size="xs"
                />
                <c-select
                  :options="filterByWeekOptions"
                  placeholder="Filter By"
                  class="select-class"
                  @input="$queryBuilder({ period: $event }, getOrgAbsence)"
                />
              </div>
            </div>
          </template>
        </CardFooter>
      </c-card>

      <template v-if="activeView === 'register'">
        <c-table
          :headers="headers"
          :items="absenceOptions"
          aria-label="ClockInOut Table"
          :loading="isFetching"
          :has-number="true"
          :page-sync="true"
          :pagination-list="absenceMeta"
          @page="$queryBuilder({ page: $event }, getOrgAbsence)"
          @itemsPerPage="$queryBuilder({ perPage: $event }, getOrgAbsence)"
          v-if="absenceOptions.length || isFetching"
        >
          <template v-slot:item="{ item }">
            <div v-if="item.date" class="text-darkPurple text-sm">
              <span v-if="!item.data.date">-</span>
              <span v-else>{{
                $DATEFORMAT(new Date(item.data.date), "MMM dd, yyyy")
              }}</span>
            </div>
            <div
              v-if="item.employeeDetails"
              class="flex justify-start items-start gap-2"
            >
              <div>
                <span
                  v-if="item.data.employeeDetails.photo"
                  class="flex justify-center items-center w-9 h-9"
                >
                  <img
                    :src="item.data.employeeDetails.photo"
                    class="w-9 h-9 rounded"
                    alt="profile photo"
                  />
                </span>
                <div
                  v-else
                  class="flex justify-center items-center w-9 h-9 rounded border"
                >
                  <span
                    class="text-blueCrayola text-center font-semibold text-lg p-2"
                  >
                    {{
                      $getInitials(
                        `${item.data.employeeDetails.fname} ${item.data.employeeDetails.lname}`
                      )
                    }}
                  </span>
                </div>
              </div>
              <div class="flex flex-col justify-start gap-0">
                <span class="font-semibold text-darkPurple text-base">
                  {{ item.data.employeeDetails.fname }}
                  {{ item.data.employeeDetails.lname }}
                </span>
                <span class="font-semibold text-blueCrayola text-xs uppercase">
                  {{
                    item.data.employeeDetails.userDesignation
                      ? item.data.employeeDetails.userDesignation.name
                      : "---"
                  }}
                </span>
              </div>
            </div>
            <span v-if="item.location" class="text-darkPurple text-sm">
              {{ item.data.location ? item.data.location.name : "---" }}
            </span>
            <div
              v-if="item.category"
              class="rounded-md p-2 text-sm w-28 text-center capitalize bg-blue-100 text-blueCrayola"
            >
              {{ item.data.category }}
            </div>
            <c-menu v-if="item.id" left @click.stop="">
              <template v-slot:title>
                <c-icon icon-name="more_icon" size="xs" />
              </template>
              <div
                class="flex flex-col w-56 h-18 p-2 justify-start items-start"
              >
                <div
                  class="w-full"
                  @click="
                    $router.push({
                      name: 'EmployeeSchedule',
                      params: { id: item.data.id },
                      query: { currentTab: $route.query.currentTab },
                    })
                  "
                >
                  <div class="hover:bg-ghostWhite w-full rounded-md">
                    <div
                      class="flex justify-start items-center p-2 gap-2 cursor-pointer"
                    >
                      <c-icon
                        icon-name="icon-eye"
                        class-name="text-blueCrayola"
                        size="xs"
                      />
                      <span
                        class="text-darkPurple text-sm leading-5 font-normal"
                        >View Work Schedule</span
                      >
                    </div>
                  </div>
                </div>
                <!-- <div class="w-full" @click="() => {}">
                <div class="hover:bg-ghostWhite w-full rounded-md">
                  <div class="flex justify-start items-center p-2 gap-2 cursor-pointer">
                    <c-icon icon-name="comment-outline" class-name="text-carrotOrange" size="xs" />
                    <span class="text-darkPurple text-sm leading-5 font-normal">Query</span>
                  </div>
                </div>
              </div> -->
              </div>
            </c-menu>
          </template>
        </c-table>

        <div
          v-else
          class="flex flex-col justify-center items-center gap-0 px-10"
        >
          <c-icon
            icon-name="paygrade_Illustration"
            size=""
            class-name="w-56 h-56"
          />
          <p class="font-normal text-base leading-5 text-jet text-center w-1/2">
            Time tracking locations can be found here. This is empty now, but
            you can start adding time tracking locations to populate this area.
          </p>
        </div>
      </template>

      <calendar
        ref="$c"
        :time="false"
        hide-title-bar
        hide-view-selector
        :events="absenceOptions"
        :selected-date="selectedDate"
        events-on-month-view
        :disable-views="['years', 'year', 'week', 'day']"
        :cell-contextmenu="true"
        @view-change="onChangeView($event)"
        class="calendar-class"
        v-if="activeView === 'calendar'"
      >
        <template #event="{ event }">
          <template v-if="event.employees.length">
            <template
              v-if="
                isEqual(
                  new Date(new Date(event.date).getMonth()),
                  new Date(new Date(selectedDate).getMonth())
                )
              "
            >
              <div
                @click="(activeAbesence = event), (isOpen = true)"
                class="flex justify-center items-center gap-2 cursor-pointer"
              >
                <p
                  class="text-desire text-xs bg-red-100 border border-desire border-solid rounded-md p-2 w-full"
                >
                  {{ event.employees.length }} Employee
                  <span class="underline ml-1">View</span>
                </p>
              </div>
            </template>
          </template>
        </template>
      </calendar>

      <RightSideBar
        v-if="isOpen"
        @close="isOpen = false"
        :close-button="true"
        width="width:520px"
      >
        <template v-slot:title>
          <div class="flex justify-start items-center gap-0">
            <back-button
              variant="secondary"
              style="padding: 0"
              @click="isOpen = false"
            />
            <span class="font-bold text-lg text-darkPurple"
              >View Employees</span
            >
          </div>
        </template>
        <template v-slot:sub-title>
          <div class="flex flex-col justify-start items-start gap-2">
            <div class="flex justify-start items-center gap-0">
              <back-button
                variant="secondary"
                style="padding: 0"
                @click="isOpen = false"
              />
              <span class="font-bold text-lg text-darkPurple"
                >View Employees</span
              >
            </div>
            <span class="font-normal text-sm text-romanSilver">
              The system only shows conflicts for the same job role and
              function.
            </span>
          </div>
        </template>

        <div v-if="true" class="flex flex-col justify-start gap-12">
          <div class="w-full flex flex-col justify-start items-start gap-6">
            <p v-if="activeAbesence.date" class="text-darkPurple">
              Absent employees for
              {{ $DATEFORMAT(new Date(activeAbesence.date), "MMMM dd, yyyy") }}
            </p>
            <div class="w-full flex justify-between items-center relative">
              <search-input
                class="w-full"
                @input="onSearchEmployees($event, 100)"
                placeholder="Search employee(s) or filter by"
              />
              <c-icon
                icon-name="sort_size"
                size="xs"
                class="absolute right-4 cursor-pointer"
              />
            </div>
          </div>
          <div>
            <div
              v-for="employee in activeAbesence.employeeDetails"
              :key="employee.id"
              class="w-full flex flex-col justify-start items-start gap- border-b border-romanSilver border-dashed py-4"
            >
              <div class="flex justify-start items-start gap-2">
                <div>
                  <span
                    v-if="employee.photo"
                    class="flex justify-center items-center w-11 h-11"
                  >
                    <img
                      :src="employee.photo"
                      class="w-11 h-11 rounded"
                      alt="profile photo"
                    />
                  </span>
                  <div
                    v-else
                    class="flex justify-center items-center w-11 h-11 rounded border"
                  >
                    <span
                      class="text-blueCrayola text-center font-semibold text-lg p-2"
                    >
                      {{ $getInitials(`${employee.fname} ${employee.lname}`) }}
                    </span>
                  </div>
                </div>
                <div class="flex flex-col justify-start gap-1">
                  <p class="font-semibold text-darkPurple text-base">
                    {{ employee.fname }} {{ employee.lname }}
                  </p>
                  <div class="flex justify-start items-center gap-2">
                    <p class="font-bold text-xs text-romanSilver uppercase">
                      {{ employee.orgFunction && employee.orgFunction.name }}
                    </p>
                    <p class="font-semibold text-xs text-romanSilver uppercase">
                      •
                      {{
                        employee.userDesignation &&
                        employee.userDesignation.name
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="flex flex-col justify-center items-center gap-0">
          <c-icon icon-name="empty-conflicts" size="" class="w-80" />
          <p class="text-base text-jet -mt-10">
            There is absent employees for this month.
          </p>
        </div>
      </RightSideBar>

      <FilterTable
        v-if="isOpenFilter"
        :filter-data="filterOptions"
        @close="isOpenFilter = false"
        @applyFilter="onSubmitFilter($event)"
      />
    </div>
  </div>
</template>

<script>
import { isEqual } from "lodash";
import Calendar from "vue-cal";
import "vue-cal/dist/vuecal.css";
import CTable from "@scelloo/cloudenly-ui/src/components/table";
import CCard from "@scelloo/cloudenly-ui/src/components/card";
import CMenu from "@scelloo/cloudenly-ui/src/components/Menu";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import SearchInput from "@scelloo/cloudenly-ui/src/components/search-input";
import CIcon from "@/components/Icon";
import CardFooter from "@/components/CardFooter";
import FilterTable from "@/components/FilterTable";
import RightSideBar from "@/components/RightSideBar";
import ErrorPage from "@/modules/Admin/error403";

export default {
  components: {
    Calendar,
    BackButton,
    SearchInput,
    ErrorPage,
    CMenu,
    CCard,
    CIcon,
    CSelect,
    CardFooter,
    CTable,
    FilterTable,
    RightSideBar,
  },
  computed: {
    radiusInMeters() {
      return Array.from({ length: 50 }, (_, i) => i + 1);
    },
    filterOptions() {
      return [
        {
          header: "Location",
          value: "location",
          optionsBox: this.locationOptions,
        },
        {
          header: "Function",
          value: "function",
          optionsBox: this.functionOptions,
        },
        {
          header: "Designation",
          value: "designation",
          optionsBox: this.designationOptions,
        },
        {
          header: "Job Level",
          value: "jobLevel",
          optionsBox: this.levelOptions,
        },
        {
          header: "Employment Type",
          value: "employmentType",
          optionsBox: this.employmentType,
        },
        { header: "Status", value: "status", optionsBox: this.statusOptions },
      ];
    },
  },
  data() {
    return {
      isEqual,
      showError: false,
      isOpen: false,
      isOpenFilter: false,
      isFetching: true,
      isSubmitting: false,
      analytics: {},
      absenceOptions: [],
      absenceMeta: {},
      activeView: "register",
      locationOptions: [],
      selectedLocation: {},
      selectedDate: new Date(),
      functionOptions: [],
      designationOptions: [],
      levelOptions: [],
      employmentType: [
        { id: "fulltime", name: "Full-time" },
        { id: "parttime", name: "Part-time" },
        { id: "intern", name: "Intern" },
        { id: "companypaid", name: "Company-paid Temp" },
        { id: "independentcontractor", name: "Independent Contractor" },
        { id: "agencypaid", name: "Agency-paid Temp" },
        { id: "vendoremployee", name: "Vendor Employee" },
        { id: "volunteer", name: "Volunteer" },
      ],
      activeAbesence: {},
      activeConflict: {},
      calendarData: [],
      conflictedLeave: [],
      headers: [
        { title: "Date", value: "date" },
        { title: "Employee Name", value: "employeeDetails" },
        { title: "Work Location", value: "location" },
        { title: "Absence Status", value: "category" },
        { title: "", value: "id", image: true },
      ],
      statusOptions: [
        { name: "Active", id: "active" },
        { name: "Inactive", id: "inactive" },
      ],
      filterByWeekOptions: [
        { name: "All", id: "all" },
        { name: "Today", id: "today" },
        { name: "This Week", id: "this_week" },
        { name: "This Month", id: "this_month" },
        { name: "Custom", id: "custom" },
      ],

      payload: {
        locationIds: [],
        address: null,
        clockInRadius: null,
        clockOutRadius: null,
      },
    };
  },
  methods: {
    onSubmitFilter(options) {
      const mergedObj = options.reduce((acc, obj) => ({ ...acc, ...obj }), {});
      this.$queryBuilder(mergedObj, this.getOrgAbsence);
      this.isOpenFilter = false;
    },
    async onSwitchActiveView(activeView) {
      this.activeView = activeView;
      const queryParams = {
        month: this.selectedDate.getMonth() + 1,
        year: this.selectedDate.getFullYear(),
        view: this.activeView,
      };
      await this.$queryBuilder(queryParams, this.getOrgAbsence);
    },
    async onChangeView($event) {
      this.selectedDate = $event.startDate;
      const queryParams = {
        month: this.selectedDate.getMonth() + 1,
        year: this.selectedDate.getFullYear(),
        view: this.activeView,
      };
      await this.$queryBuilder(queryParams, this.getOrgAbsence);
    },
    async getOrgAbsence(params) {
      this.isFetching = true;
      await this.$_getOrgAbsence(
        {params}
      ).then(({ data }) => {
        this.analytics = data.analytics || {};
        this.absenceMeta = data.meta;

        if (this.activeView === "register") {
          this.absenceOptions = data.absenceData.map((item) => ({
            ...item,
            id: item.employeeDetails.id,
            start: item.date,
            end: item.date,
            location: item.employeeDetails.employeeOffice,
          }));
        } else if (this.activeView === "calendar") {
          this.absenceOptions = data.calendarData.map((item) => ({
            ...item,
            start: item.date,
            end: item.date,
          }));
        }
        this.isFetching = false;
      });
    },

    async getLocations() {
      this.$_getEmployeesLocationDistribution().then((response) => {
        response.data.locations.forEach((location) => {
          this.locationOptions.push({
            id: location.id,
            name: location.name,
          });
        });
      });
    },

    async getFunctions() {
      this.$_getFunction().then((response) => {
        response.data.functions.forEach((functions) => {
          this.functionOptions.push({
            id: functions.id,
            name: functions.name,
          });
        });
      });
    },

    async getDesignations() {
      this.$_getDesignations("").then((response) => {
        response.data.designations.forEach((designation) => {
          this.designationOptions.push({
            id: designation.id,
            name: designation.name,
          });
        });
      });
    },

    async getJobLevels() {
      this.$_getLevels().then((response) => {
        response.data.levels.forEach((level) => {
          this.levelOptions.push({
            id: level.id,
            name: level.name,
          });
        });
      });
    },

    async bootstrapModule() {
       const queryParams = {
        month: this.selectedDate.getMonth() + 1,
        year: this.selectedDate.getFullYear(),
        view: this.activeView,
      };
      await this.$queryBuilder(queryParams, this.getOrgAbsence);
      await this.getLocations();
      await this.getFunctions();
      await this.getDesignations();
      await this.getJobLevels();
    },
  },
  async created() {
    try {
      await this.$handlePrivilege("timeAttendanceAbsence", "viewRegister");
      this.showError = false;
      this.bootstrapModule();
    } catch (error) {
      this.showError = true;
    }
  },
};
</script>

<style scoped>
::v-deep .select-class span select {
  margin-top: 3px !important;
  height: 42px !important;
  border: 0 !important;
}
.button-style {
  font-weight: 600 !important;
  font-size: 16px !important;
  font-size: 16px !important;
  line-height: 120% !important;
  padding: 10px 50px !important;
}
.c-button {
  width: 100% !important;
  padding: 10px 40px !important;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar-class {
  background: #eeeeee52;
  border-radius: 8px;
  padding: 24px;
  padding-top: 8px;
  border: 0 !important;
}
::v-deep .vuecal {
  border: 0 !important;
}
::v-deep .vuecal .vuecal__header {
  border: 0 !important;
}
::v-deep .vuecal .weekday-label {
  display: flex;
  justify-content: flex-start;
}
::v-deep .vuecal .weekday-label span {
  font-family: "Nunito Sans";
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #878e99;
}
::v-deep .vuecal .vuecal__cell {
  height: 126px !important;
  border-color: #878e99 !important;
  border-style: solid !important;
  border-width: 0.5px !important;
}
::v-deep .vuecal .vuecal__cell-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 8px;
}
</style>
